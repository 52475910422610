/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@font-face {
    font-family: Oswald;
    src: url(./assets/fonts/Oswald/Oswald-VariableFont_wght.ttf) format("opentype");
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root {
    --main-color: #17165e;
    --text-color: #4c4c4c;
    --error-color: #e01919;
    --inactive-field-color: #a0a0a0;
    --text2-color: #808080;

    * {
        font-family: Lato;
    }
    body {
        margin: 0;
    }
    .btn-container {
        .main-btn {
            padding: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            background: var(--main-color);
            color: white;
            border-radius: 22px;
            border: none;
            outline: none;
            min-width: 200px;
        }
        .delete-btn {
            background: var(--error-color);
            color: #ffffff;
            border: 1px solid var(--error-color);
            outline: none;
            padding: 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            border-radius: 22px;
            min-width: 200px;
        }
        .save-btn {
            padding: 14px 90px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            background: #ffffff;
            color: var(--main-color);
            border: 1px solid var(--main-color);
            border-radius: 5px;
            outline: none;
        }
        .outline-btn {
            padding: 14px 90px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            background: transparent;
            color: var(--main-color);
            border: none;
            border-radius: 5px;
            outline: none;
        }
        button {
            &:disabled {
                background: var(--inactive-field-color);
                color: var(--text2-color);
            }
        }
    }
    .input-container {
        text-align: start;
        position: relative;
        label {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .input {
            input,
            select,
            textarea {
                width: 100%;
                height: 40px;
                padding: 11px;
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                margin: 8px 0px 16px 0px;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: start;
                outline: none;

                border-radius: 46px;
                border: 1px solid var(--Stroke, #e6e6e6);
                background: white;
            }
            select {
                padding: 0px 11px;
                appearance: none;
                background: url("./assets/icons/arrow-down.svg") no-repeat 15px white;
                background-position: right 15px center;
                &::before {
                    margin-left: 10px;
                }
            }
            textarea {
                height: 100px;
                border-radius: 25px;
                resize: none;
            }
            .flatpickr-input {
                margin-top: 8px !important;
            }
            .icon {
                position: absolute;
                right: 10px;
                top: calc(50% + 4px);
            }
            .error-text{
                display: none;
            }
            &.error {
                input {
                    margin-bottom: 0;
                    border-color: red;
                }
                .error-text {
                    color: red;
                    margin-bottom: 5px;
                    display: block;
                }
            }
        }
    }
    // .pagination {
    //     border-top: 1px solid #c4c4c4;
    //     padding: 15px 25px;
    //     background: #ffffff;
    //     align-items: center;
    //     position: relative;

    //     .total {
    //         position: absolute;
    //         right: 20px;
    //         font-weight: 400;
    //         font-size: 14px;
    //         line-height: 17px;
    //         color: var(--text-color);
    //     }
    //     .page-size {
    //         margin-left: 11px;
    //         font-weight: 400;
    //         font-size: 14px;
    //         line-height: 17px;
    //         color: var(--text-color);
    //         select {
    //             background: #ffffff;
    //             padding: 2px 5px 2px 6px;
    //             box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16),
    //                 0px 2px 5px rgba(89, 96, 120, 0.1);
    //             border-radius: 6px;
    //         }
    //     }
    //     .page-number {
    //         .btn {
    //             display: inline-block;
    //             padding: 0px 4px;
    //             width: 24px;
    //             height: 22px;
    //             box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.24);
    //             border-radius: 6px;
    //             background-color: #ffffff;
    //             margin-right: 12px;
    //             &.back {
    //                 transform: rotate(180deg);
    //             }
    //             &.disabled {
    //                 background: #f7f9fc;
    //                 pointer-events: none;
    //             }
    //         }
    //         .page {
    //             display: inline-block;
    //             margin-right: 12px;
    //         }
    //     }
    // }
    .loading {
        width: 100%;
        height: 100%;
        background-color: rgba(160, 160, 160, 0.439);
        position: fixed;
        top: 0;
        left: 0;
        text-align: center;
        z-index: 10000;
        .gif {
            position: absolute;
            width: 100px;
            height: 100px;
            left: calc(50% - 50px);
            top: calc(50% - 50px);
            content: url("./assets/icons/loading-2.gif");
        }
    }
}
